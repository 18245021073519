<template>
  <div>
    <dialog-header title="Lookup results" />

    <section-container v-if="!policyValidated">
      <div
        class="rounded-lg bg-fv-red-light bg-opacity-50 px-4 py-4 text-red-800"
      >
        <div class="flex items-center space-x-4">
          <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
          <p>
            No {{ selectedPartner.insurance.name }} policy was found for the
            provided details.
          </p>
        </div>
      </div>
    </section-container>

    <section-container
      v-if="policyValidated"
      :is-gray="true"
      :loading="loading"
      class="mt-4"
    >
      <div
        class="borderContent flex flex-col space-y-4 rounded border border-gray-200 px-2 py-4"
      >
        <div v-if="selectedUser" class="border-b pb-4">
          <p class="mb-2 font-semibold">FirstVet Account</p>
          <div class="flex items-center justify-between pr-4">
            <div class="text-gray-600">
              <p>{{ selectedUser.firstName }} {{ selectedUser.lastName }}</p>
              <p>{{ selectedUser.phone }}</p>
              <p>{{ selectedUser.email }}</p>
            </div>
            <span
              v-if="selectedUser.tosAccepted"
              class="rounded-full bg-primary px-2 py-1 text-sm text-white"
              >Verified</span
            >
          </div>
        </div>
        <div class="flex items-center justify-between pr-4">
          <div>
            <p class="mb-2 font-semibold">Policy lookup</p>
            <div class="text-gray-600">
              <p>{{ selectedPartner.insurance.name }}</p>
            </div>
          </div>
          <span class="rounded-full bg-primary px-2 py-1 text-sm text-white">
            Verified
          </span>
        </div>
      </div>

      <div class="mt-2">
        <dialog-header title="Select a pet to start a booking" />
      </div>

      <div
        class="mt-2 flex flex-col space-y-4 rounded border border-gray-200 px-4"
      >
        <ul class="">
          <li
            v-for="userAnimal in animalsWithCorrectInsurance"
            :key="userAnimal.id"
            class="border-b py-4"
          >
            <div class="flex items-center justify-between">
              <div>
                <p class="block">
                  {{ userAnimal.name }}
                </p>
                <p class="mt-1 block text-gray-600">
                  {{ userAnimal.animalType.name }}
                </p>
              </div>

              <button
                class="py-2 px-3"
                @click="handleAnimalSelected(userAnimal)"
              >
                <font-awesome-icon
                  icon="chevron-right"
                  class="text-gray-600 hover:text-gray-800"
                ></font-awesome-icon>
              </button>
            </div>
          </li>

          <li
            v-for="lookupAnimal in lookupAnimalsCompared"
            :key="lookupAnimal.name"
            class="border-b py-4"
            :class="{ 'opacity-50': lookupAnimal.existsOnUser }"
          >
            <div class="flex items-center justify-between">
              <div>
                <p
                  class="block"
                  :class="{ 'line-through': lookupAnimal.existsOnUser }"
                >
                  {{ lookupAnimal.name }}
                </p>
                <p class="mt-1 block text-gray-600">
                  {{ lookupAnimal.type }}, {{ lookupAnimal.gender }}
                </p>
              </div>
              <div class="inline-flex space-x-4">
                <span
                  v-if="policyValidated"
                  class="inline-flex items-center rounded-full bg-fv-green px-2 py-1 text-center text-sm text-white"
                  >Lookup</span
                >

                <button
                  class="py-2 px-3"
                  :disabled="lookupAnimal.existsOnUser"
                  :class="{ 'cursor-default': lookupAnimal.existsOnUser }"
                  @click="handleAnimalSelected(lookupAnimal)"
                >
                  <font-awesome-icon
                    icon="chevron-right"
                    class="text-gray-600 hover:text-gray-800"
                  ></font-awesome-icon>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import getAnimalTypeIdFromString from '@/utils/helpers/animal-type-helpers';
import getAnimalGenderValueFromString from '@/utils/helpers/animal-gender-helpers';
import { format, parseISO } from 'date-fns';
import { errorHandler } from '@/utils/error-handler';
import DialogHeader from '../DialogHeader.vue';
import SectionContainer from '../SectionContainer.vue';

export default {
  components: {
    DialogHeader,
    SectionContainer,
  },

  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('remoteBooking', [
      'selectedPartner',
      'selectedUser',
      'selectedAnimal',
      'lookupAnimalList',
      'userAnimalList',
      'policyValidated',
      'userInput',
    ]),
    countryId() {
      return this.user.country_id;
    },
    animalsWithCorrectInsurance() {
      return this.userAnimalList.filter(
        animal =>
          animal.insuranceCompany?.id &&
          animal.insuranceCompany.id === this.selectedPartner.insurance.id
      );
    },
    lookupAnimalsCompared() {
      return this.lookupAnimalList.map(lookupAnimal => {
        return {
          ...lookupAnimal,
          existsOnUser: this.animalExistsInUserList(lookupAnimal),
        };
      });
    },
  },
  async mounted() {},
  methods: {
    ...mapActions('remoteBooking', [
      'createUserByAttributes',
      'addAnimalToUser',
      'updateAnimal',
      'fetchAnimal',
    ]),
    ...mapMutations('remoteBooking', ['setSelectedAnimal']),

    async handleAnimalSelected(animal) {
      this.loading = true;

      try {
        if (!this.selectedUser) {
          await this.handleCreateUser();
        }

        if (animal.id) {
          this.setSelectedAnimal(animal);
          this.loading = false;
          this.$emit('next-page');
          return;
        }
        const { name, type, birthDate, gender } = animal;

        const formattedBirthDate = this.parseBirthDate(birthDate);

        const payload = {
          animalName: name,
          animalType: getAnimalTypeIdFromString(type),
          animalGender: getAnimalGenderValueFromString(gender),
          animalBirthdate: formattedBirthDate,
        };

        await this.addAnimalToUser(payload);

        const updatePayload = {
          insuranceId: this.selectedPartner.insurance.id,
          insurance: animal.uuid,
        };

        await this.updateAnimal(updatePayload);
        await this.fetchAnimal({
          animalId: this.selectedAnimal.id,
          userId: this.selectedUser.id,
        });

        this.loading = false;
        this.$emit('next-page');
      } catch (error) {
        errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    async handleCreateUser() {
      try {
        await this.createUserByAttributes(this.countryId);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    parseBirthDate(birthDate) {
      if (!birthDate) {
        return format(new Date(), 'y-MM-dd');
      }
      const birthDateObject = parseISO(birthDate);
      const formatted = format(birthDateObject, 'y-MM-dd');

      return formatted;
    },

    animalExistsInUserList(animal) {
      if (!this.userAnimalList.length) {
        return;
      }
      const animalBySameName = this.userAnimalList.find(
        userAnimal =>
          animal.name.toLowerCase() === userAnimal.name.toLowerCase()
      );
      return !!animalBySameName;
    },
  },
};
</script>
