<template>
  <base-modal
    :show="true"
    :size="modalSize"
    @close="handleCleanupSelectedAppointment"
  >
    <div v-if="!uiReady" class="min-h-full">
      <div class="flex flex-col items-center justify-center">
        <p>Retrieving data..</p>
        <base-spinner v-if="!uiReady"></base-spinner>
      </div>
    </div>
    <div v-if="uiReady" id="bookingContainer" class="container mb-4 p-4">
      <header class="grid grid-cols-3">
        <div class="col-span-1">
          <button v-show="!isLastPage && !isFirstPage" @click="prevPage">
            <fv-icon class="text-xs text-gray-700" icon="chevron-left" />
          </button>
        </div>
        <div class="col-span-1">
          <img
            class="w-35 mx-auto h-6"
            :src="require('@/assets/svg/firstvet_logo.svg')"
          />
        </div>
        <div class="col-span-1 text-right">
          <button @click="handleCleanupSelectedAppointment">
            <fv-icon class="text-xs text-gray-700" icon="close" />
          </button>
        </div>
      </header>
      <section class="pt-3">
        <component
          :is="currentPageComponent.component"
          v-if="currentPageComponent"
          :page="currentPageComponent.props.page"
          @close="event => handleCleanupSelectedAppointment()"
          @next-page="nextPage"
          @first-page="firstPage"
        ></component>
        <p v-else>Something went wrong, refresh the page and try again</p>
      </section>
    </div>
  </base-modal>
</template>

<script>
import {
  TWENTY_TWO_EIGHTY_EIGHT_BOOKING_FLOW,
  VETPANEL_FOLLOWUP_FLOW,
} from '@/store/modules/remote-booking-store';
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import { RemoteBookingApi } from '@/api';
import { errorHandler } from '@/utils/error-handler';
import SelectPartner from './account-creation/SelectPartner.vue';
import SelectService from './booking/SelectService.vue';
import SelectTimeSlot from './booking/SelectTimeslot.vue';
import CaseDescription from './booking/CaseDescription.vue';
import ConfirmBooking from './booking/ConfirmBooking.vue';
import AccountAndLookupResult from './account-creation/AccountAndLookupResult.vue';
import SmsConfirm from './booking/SmsConfirm.vue';
import Success from './booking/Success.vue';

export default {
  components: {
    SelectPartner,
    SelectService,
    SelectTimeSlot,
    CaseDescription,
    ConfirmBooking,
    SmsConfirm,
    Success,
    AccountAndLookupResult,
  },
  props: {
    accountCreation: {
      type: Boolean,
      default: false,
    },
    animal: {
      type: [Object || null],
      default: null,
    },
    customer: {
      type: [Object || null],
      default: null,
    },
    previousAppointmentId: {
      type: [Number || null],
      default: null,
    },
  },
  data() {
    return {
      page: 0,

      uiReady: false,

      steps: {
        selectPartner: {
          component: 'SelectPartner',
          props: {},
        },
        accountAndLookupResult: {
          component: 'AccountAndLookupResult',
          props: {},
        },
        selectService: {
          component: 'SelectService',
          props: {},
        },
        selectTimeSlot: {
          component: 'SelectTimeSlot',
          props: {},
        },
        caseDescription: {
          component: 'CaseDescription',
          props: {},
        },
        confirmBooking: {
          component: 'ConfirmBooking',
          props: {},
        },
        smsConfirm: {
          component: 'SmsConfirm',
          props: {},
        },
        success: {
          component: 'Success',
          props: {},
        },
      },
    };
  },
  computed: {
    ...mapState(['platform']),
    ...mapState('remoteBooking', ['bookingFlow']),
    ...mapGetters('remoteBooking', {
      selectedAnimal: 'getSelectedAnimal',
    }),

    isLastPage() {
      if (!this.currentBookingFlow) {
        return false;
      }
      return this.page === Object.keys(this.currentBookingFlow).length - 1;
    },

    isFirstPage() {
      return this.page === 0;
    },

    currentBookingFlow() {
      const twentyTwoEightyEightyFlow = {
        0: this.steps.selectPartner,
        1: this.steps.accountAndLookupResult,
        2: this.steps.selectService,
        3: this.steps.selectTimeSlot,
        4: this.steps.caseDescription,
        5: this.steps.confirmBooking,
        6: this.steps.smsConfirm,
        7: this.steps.success,
      };

      const vetpanelBookingFlow = {
        0: this.steps.selectService,
        1: this.steps.selectTimeSlot,
        2: this.steps.caseDescription,
        3: this.steps.confirmBooking,
        4: this.steps.success,
      };

      switch (this.bookingFlow) {
        case TWENTY_TWO_EIGHTY_EIGHT_BOOKING_FLOW:
          return twentyTwoEightyEightyFlow;
        case VETPANEL_FOLLOWUP_FLOW:
          return vetpanelBookingFlow;
        default:
          return null;
      }
    },

    currentPageComponent() {
      if (!this.currentBookingFlow) {
        return false;
      }

      if (!Object.hasOwn(this.currentBookingFlow, this.page)) {
        return false;
      }

      return this.currentBookingFlow[this.page];
    },

    modalSize() {
      return 'md';
    },
  },
  async mounted() {
    await this.populateStore();

    if (this.accountCreation) {
      this.setBookingFlow(TWENTY_TWO_EIGHTY_EIGHT_BOOKING_FLOW);
    } else {
      this.setBookingFlow(VETPANEL_FOLLOWUP_FLOW);
    }
    this.uiReady = true;
  },
  methods: {
    ...mapMutations('remoteBooking', [
      'setSelectedAnimal',
      'setSelectedUser',
      'setPreviousAppointmentId',
      'setBookingFlow',
      'clearState',
    ]),
    ...mapActions('remoteBooking', ['fetchUser', 'fetchAnimal']),

    nextPage() {
      this.page = this.page + 1;
    },
    firstPage() {
      this.page = 0;
    },
    prevPage() {
      if (this.page === 0) {
        this.$emit('close');
        return;
      }
      this.page = this.page - 1;
    },
    async handleCleanupSelectedAppointment() {
      const appointmentId = this.selectedAppointment?.appointmentId;
      const appointmentStatus = this.selectedAppointment?.status;
      if (appointmentId && appointmentStatus === 'pending') {
        try {
          await RemoteBookingApi.deleteSlotReservation(appointmentId);
        } catch (error) {
          errorHandler(error);
        } finally {
          this.$emit('close');
        }
      }
      // Clear state of all values
      this.clearState();

      this.$emit('close');
    },

    async populateStore() {
      // if animal and customer are passed in as props, fetch them throuh store actions
      if (this.animal && this.customer) {
        await Promise.all([
          this.fetchAnimal({
            animalId: this.animal.id,
            userId: this.customer.id,
          }),
          this.fetchUser(this.customer.id),
        ]);
      }

      // if previousAppointment is passed in as a prop, set them in the store
      if (this.previousAppointmentId) {
        this.setPreviousAppointmentId(this.previousAppointmentId);
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.container {
  max-height: 600px;
}
</style>
