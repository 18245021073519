var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dialog-header", {
        attrs: {
          title: "Confirm booking",
          description:
            "Make sure the information is correct and confirm the booking to send booking information to the pet parent.",
        },
      }),
      _c(
        "section-container",
        { attrs: { loading: !_vm.isReady } },
        [
          _vm.errorMessage
            ? _c("div", { staticClass: "font-semibold text-red-900" }, [
                _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "divide-y rounded border border-gray-200" },
            [
              _c(
                "div",
                { staticClass: "flex w-full justify-between align-middle" },
                [
                  _c("p", { staticClass: "px-4 py-5" }, [
                    _vm._v("Time of appointment"),
                  ]),
                  _c("p", { staticClass: "px-4 py-5 text-gray-500" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.format(
                            new Date(_vm.selectedAppointment.slotTime),
                            "yyyy-MM-dd HH:mm"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "flex w-full justify-between align-middle" },
                [
                  _c("p", { staticClass: "px-4 py-5" }, [
                    _vm._v("Phone number"),
                  ]),
                  _c("p", { staticClass: "px-4 py-5 text-gray-500" }, [
                    _vm._v(
                      " " + _vm._s(_vm.selectedUser.phoneNumber.e164) + " "
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "flex w-full justify-between align-middle" },
                [
                  _c("p", { staticClass: "px-4 py-5" }, [_vm._v("Pet")]),
                  _vm.selectedAnimal
                    ? _c("p", { staticClass: "px-4 py-5 text-gray-500" }, [
                        _vm._v(" " + _vm._s(_vm.selectedAnimal.name) + " "),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex w-full justify-between border-b border-gray-200 align-middle",
                },
                [
                  _c("p", { staticClass: "px-4 py-5" }, [_vm._v("Vet")]),
                  _c("p", { staticClass: "px-4 py-5 text-gray-500" }, [
                    _vm._v(_vm._s(_vm.selectedAppointment.vet)),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "flex w-full justify-between align-middle" },
                [
                  _c("p", { staticClass: "px-4 py-5" }, [_vm._v("Service")]),
                  _c("p", { staticClass: "px-4 py-5 text-gray-500" }, [
                    _vm._v(_vm._s(_vm.selectedService.name)),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "base-button",
            {
              staticClass: "mt-auto w-full",
              attrs: { color: "primary", size: "lg" },
              on: { click: _vm.handleConfirmation },
            },
            [
              _vm._v(" " + _vm._s(_vm.ctaText) + " "),
              _c("fv-icon", {
                staticClass: "text-lg text-white",
                attrs: { icon: "chevron-right" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }