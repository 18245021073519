var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dialog-header", {
        attrs: {
          title: "Success! Booking completed.",
          description:
            "We have sent the booking information to the pet parent.",
        },
      }),
      _c(
        "section-container",
        [
          _c(
            "div",
            { staticClass: "w-full rounded border border-gray-200 p-4" },
            [
              _c("h3", { staticClass: "py-4 font-semibold" }, [
                _vm._v("What's next?"),
              ]),
              _c("p", { staticClass: "text-gray-500" }, [
                _vm._v(
                  " The meeting will take place in our app and the booking information has been sent to the pet parent. "
                ),
              ]),
              _c("br"),
              _c("p", { staticClass: "text-gray-500" }, [
                _vm._v(
                  " Pet parent has to download the app from app store / play store. After logging in they will be able to access their account and the appointment. "
                ),
              ]),
              _c("br"),
              _c("p", { staticClass: "text-gray-500" }, [
                _vm._v(
                  " They will find the booking on the home screen of the app. "
                ),
              ]),
            ]
          ),
          _c(
            "base-button",
            {
              staticClass: "mt-auto w-full",
              attrs: { color: "primary", size: "lg" },
              on: {
                click: function (event) {
                  return this$1.$emit("close")
                },
              },
            },
            [_vm._v("Back to meeting room")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }