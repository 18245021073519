<template>
  <header class="text-center">
    <h2 class="mt-4 mb-4 font-display text-xl font-semibold">{{ title }}</h2>
    <p class="mb-4 text-gray-500" v-html="formattedContent"></p>
  </header>
</template>

<script>
export default {
  name: 'DialogHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    formattedContent() {
      return this.description.replace(/\n/g, '<br>');
    },
  },
};
</script>
