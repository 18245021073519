var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: { show: true, size: _vm.modalSize },
      on: { close: _vm.handleCleanupSelectedAppointment },
    },
    [
      !_vm.uiReady
        ? _c("div", { staticClass: "min-h-full" }, [
            _c(
              "div",
              { staticClass: "flex flex-col items-center justify-center" },
              [
                _c("p", [_vm._v("Retrieving data..")]),
                !_vm.uiReady ? _c("base-spinner") : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.uiReady
        ? _c(
            "div",
            {
              staticClass: "container mb-4 p-4",
              attrs: { id: "bookingContainer" },
            },
            [
              _c("header", { staticClass: "grid grid-cols-3" }, [
                _c("div", { staticClass: "col-span-1" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isLastPage && !_vm.isFirstPage,
                          expression: "!isLastPage && !isFirstPage",
                        },
                      ],
                      on: { click: _vm.prevPage },
                    },
                    [
                      _c("fv-icon", {
                        staticClass: "text-xs text-gray-700",
                        attrs: { icon: "chevron-left" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-span-1" }, [
                  _c("img", {
                    staticClass: "w-35 mx-auto h-6",
                    attrs: { src: require("@/assets/svg/firstvet_logo.svg") },
                  }),
                ]),
                _c("div", { staticClass: "col-span-1 text-right" }, [
                  _c(
                    "button",
                    { on: { click: _vm.handleCleanupSelectedAppointment } },
                    [
                      _c("fv-icon", {
                        staticClass: "text-xs text-gray-700",
                        attrs: { icon: "close" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "section",
                { staticClass: "pt-3" },
                [
                  _vm.currentPageComponent
                    ? _c(_vm.currentPageComponent.component, {
                        tag: "component",
                        attrs: { page: _vm.currentPageComponent.props.page },
                        on: {
                          close: function (event) {
                            return _vm.handleCleanupSelectedAppointment()
                          },
                          "next-page": _vm.nextPage,
                          "first-page": _vm.firstPage,
                        },
                      })
                    : _c("p", [
                        _vm._v(
                          "Something went wrong, refresh the page and try again"
                        ),
                      ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }