<template>
  <section class="sectionContainer flex flex-col pb-8">
    <slot v-if="!loading"></slot>
    <div v-else class="mx-auto mt-8 w-full">
      <div class="rounded border border-gray-200 pl-2 pt-4">
        <base-spinner
          :is-gray="isGray"
          class="mx-auto"
          size="xxxl"
        ></base-spinner>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionContainer',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isGray: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="postcss">
.sectionContainer {
  min-height: 450px;
}
</style>
