var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sectionContainer flex flex-col pb-8" },
    [
      !_vm.loading
        ? _vm._t("default")
        : _c("div", { staticClass: "mx-auto mt-8 w-full" }, [
            _c(
              "div",
              { staticClass: "rounded border border-gray-200 pl-2 pt-4" },
              [
                _c("base-spinner", {
                  staticClass: "mx-auto",
                  attrs: { "is-gray": _vm.isGray, size: "xxxl" },
                }),
              ],
              1
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }