var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { class: [_vm.colorClass, "rounded-full py-1 px-3 text-white"] },
    [_vm._v(" " + _vm._s(_vm.labelText) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }