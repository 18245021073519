var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dialog-header", {
        attrs: {
          title: "Describe the case",
          description:
            "Please describe the case in as much detail as possible, so our vet can help you in the best way.",
        },
      }),
      _c(
        "section-container",
        { attrs: { loading: _vm.loading } },
        [
          _c(
            "ValidationObserver",
            {
              ref: "observer",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("ValidationProvider", {
                staticClass: "w-full",
                attrs: {
                  rules: "required",
                  name: "caseDescription",
                  vid: "caseDescription",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var errors = ref.errors
                      return [
                        _c("textarea-input", {
                          attrs: {
                            id: "caseDescription",
                            name: "caseDescription",
                            autocomplete: "off",
                            type: "text",
                            rows: 10,
                          },
                          model: {
                            value: _vm.caseDescription,
                            callback: function ($$v) {
                              _vm.caseDescription = $$v
                            },
                            expression: "caseDescription",
                          },
                        }),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.showValidationErrors && errors.length
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "block text-center font-semibold text-red-800",
                                },
                                [_vm._v(" " + _vm._s(errors[0]) + " ")]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "base-button",
            {
              staticClass: "mt-auto w-full",
              attrs: { color: "primary", size: "lg" },
              on: { click: _vm.validate },
            },
            [
              _vm._v(" Continue "),
              _c("fv-icon", {
                staticClass: "text-xs text-white",
                attrs: { icon: "chevron-right" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }