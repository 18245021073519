const animalGenderHashMap = {
  male: 'm',
  female: 'f',
  unknown: 'u',
};

export default function getAnimalGenderValueFromString(key: string): string {
  const lowerCasedKey = key.toLowerCase();

  const isOnMap = animalGenderHashMap.hasOwnProperty(lowerCasedKey);
  if (!isOnMap) {
    return 'u';
  }

  return animalGenderHashMap[lowerCasedKey];
}
