var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dialog-header", { attrs: { title: "Lookup results" } }),
      !_vm.policyValidated
        ? _c("section-container", [
            _c(
              "div",
              {
                staticClass:
                  "rounded-lg bg-fv-red-light bg-opacity-50 px-4 py-4 text-red-800",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex items-center space-x-4" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "exclamation-circle" },
                    }),
                    _c("p", [
                      _vm._v(
                        " No " +
                          _vm._s(_vm.selectedPartner.insurance.name) +
                          " policy was found for the provided details. "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm.policyValidated
        ? _c(
            "section-container",
            {
              staticClass: "mt-4",
              attrs: { "is-gray": true, loading: _vm.loading },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "borderContent flex flex-col space-y-4 rounded border border-gray-200 px-2 py-4",
                },
                [
                  _vm.selectedUser
                    ? _c("div", { staticClass: "border-b pb-4" }, [
                        _c("p", { staticClass: "mb-2 font-semibold" }, [
                          _vm._v("FirstVet Account"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-between pr-4",
                          },
                          [
                            _c("div", { staticClass: "text-gray-600" }, [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.selectedUser.firstName) +
                                    " " +
                                    _vm._s(_vm.selectedUser.lastName)
                                ),
                              ]),
                              _c("p", [_vm._v(_vm._s(_vm.selectedUser.phone))]),
                              _c("p", [_vm._v(_vm._s(_vm.selectedUser.email))]),
                            ]),
                            _vm.selectedUser.tosAccepted
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "rounded-full bg-primary px-2 py-1 text-sm text-white",
                                  },
                                  [_vm._v("Verified")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-between pr-4" },
                    [
                      _c("div", [
                        _c("p", { staticClass: "mb-2 font-semibold" }, [
                          _vm._v("Policy lookup"),
                        ]),
                        _c("div", { staticClass: "text-gray-600" }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.selectedPartner.insurance.name)),
                          ]),
                        ]),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass:
                            "rounded-full bg-primary px-2 py-1 text-sm text-white",
                        },
                        [_vm._v(" Verified ")]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("dialog-header", {
                    attrs: { title: "Select a pet to start a booking" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "mt-2 flex flex-col space-y-4 rounded border border-gray-200 px-4",
                },
                [
                  _c(
                    "ul",
                    {},
                    [
                      _vm._l(
                        _vm.animalsWithCorrectInsurance,
                        function (userAnimal) {
                          return _c(
                            "li",
                            {
                              key: userAnimal.id,
                              staticClass: "border-b py-4",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-between",
                                },
                                [
                                  _c("div", [
                                    _c("p", { staticClass: "block" }, [
                                      _vm._v(
                                        " " + _vm._s(userAnimal.name) + " "
                                      ),
                                    ]),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "mt-1 block text-gray-600",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(userAnimal.animalType.name) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "py-2 px-3",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleAnimalSelected(
                                            userAnimal
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass:
                                          "text-gray-600 hover:text-gray-800",
                                        attrs: { icon: "chevron-right" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._l(
                        _vm.lookupAnimalsCompared,
                        function (lookupAnimal) {
                          return _c(
                            "li",
                            {
                              key: lookupAnimal.name,
                              staticClass: "border-b py-4",
                              class: {
                                "opacity-50": lookupAnimal.existsOnUser,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-between",
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "block",
                                        class: {
                                          "line-through":
                                            lookupAnimal.existsOnUser,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(lookupAnimal.name) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "mt-1 block text-gray-600",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(lookupAnimal.type) +
                                            ", " +
                                            _vm._s(lookupAnimal.gender) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "inline-flex space-x-4" },
                                    [
                                      _vm.policyValidated
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "inline-flex items-center rounded-full bg-fv-green px-2 py-1 text-center text-sm text-white",
                                            },
                                            [_vm._v("Lookup")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "py-2 px-3",
                                          class: {
                                            "cursor-default":
                                              lookupAnimal.existsOnUser,
                                          },
                                          attrs: {
                                            disabled: lookupAnimal.existsOnUser,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleAnimalSelected(
                                                lookupAnimal
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass:
                                              "text-gray-600 hover:text-gray-800",
                                            attrs: { icon: "chevron-right" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }