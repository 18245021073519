<template>
  <span :class="[colorClass, 'rounded-full py-1 px-3 text-white']">
    {{ labelText }}
  </span>
</template>

<script>
export default {
  props: {
    labelText: {
      type: String,
      required: true,
    },
    color: {
      validator(value) {
        return ['blue', 'red'].includes(value);
      },
      type: String,
      default: 'blue',
    },
  },
  computed: {
    colorClass() {
      if (this.color === 'blue') {
        return 'bg-primary';
      }
      if (this.color === 'red') {
        return 'bg-red-500';
      }
      return 'bg-primary';
    },
  },
};
</script>

<style></style>
