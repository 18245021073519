<template>
  <div>
    <dialog-header
      title="Confirm booking"
      description="Make sure the information is correct and confirm the booking to send booking information to the pet parent."
    />
    <section-container :loading="!isReady">
      <div v-if="errorMessage" class="font-semibold text-red-900">
        {{ errorMessage }}
      </div>

      <div class="divide-y rounded border border-gray-200">
        <div class="flex w-full justify-between align-middle">
          <p class="px-4 py-5">Time of appointment</p>
          <p class="px-4 py-5 text-gray-500">
            {{
              format(new Date(selectedAppointment.slotTime), 'yyyy-MM-dd HH:mm')
            }}
          </p>
        </div>
        <div class="flex w-full justify-between align-middle">
          <p class="px-4 py-5">Phone number</p>
          <p class="px-4 py-5 text-gray-500">
            {{ selectedUser.phoneNumber.e164 }}
          </p>
        </div>
        <div class="flex w-full justify-between align-middle">
          <p class="px-4 py-5">Pet</p>
          <p v-if="selectedAnimal" class="px-4 py-5 text-gray-500">
            {{ selectedAnimal.name }}
          </p>
        </div>
        <div
          class="flex w-full justify-between border-b border-gray-200 align-middle"
        >
          <p class="px-4 py-5">Vet</p>
          <p class="px-4 py-5 text-gray-500">{{ selectedAppointment.vet }}</p>
        </div>
        <div class="flex w-full justify-between align-middle">
          <p class="px-4 py-5">Service</p>
          <p class="px-4 py-5 text-gray-500">{{ selectedService.name }}</p>
        </div>
      </div>
      <base-button
        class="mt-auto w-full"
        color="primary"
        size="lg"
        @click="handleConfirmation"
      >
        {{ ctaText }}

        <fv-icon class="text-lg text-white" icon="chevron-right"
      /></base-button>
    </section-container>
  </div>
</template>

<script>
import { RemoteBookingApi } from '@/api';
import {
  VETPANEL_FOLLOWUP_FLOW,
  TWENTY_TWO_EIGHTY_EIGHT_BOOKING_FLOW,
} from '@/store/modules/remote-booking-store';
import { format } from 'date-fns';
import { errorHandler } from '@/utils/error-handler';
import { mapState, mapMutations, mapActions } from 'vuex';
import DialogHeader from '../DialogHeader.vue';
import SectionContainer from '../SectionContainer.vue';

export default {
  components: {
    DialogHeader,
    SectionContainer,
  },
  data() {
    return {
      format,
      loading: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('remoteBooking', [
      'bookingFlow',
      'selectedAppointment',
      'selectedService',
      'selectedAnimal',
      'selectedUser',
      'previousAppointmentId',
    ]),
    isReady() {
      return !!(
        this.selectedAppointment &&
        this.selectedService &&
        this.selectedAnimal &&
        this.selectedUser
      );
    },
    ctaText() {
      switch (this.bookingFlow) {
        case VETPANEL_FOLLOWUP_FLOW:
          return 'Confirm and book appointment';
        case TWENTY_TWO_EIGHTY_EIGHT_BOOKING_FLOW:
          return 'Send text message';
      }
      return '';
    },
  },
  methods: {
    ...mapMutations('remoteBooking', ['setSelectedAppointment']),
    ...mapActions('remoteBooking', ['sendAppointmentConfirmationLink']),

    async handleConfirmation() {
      this.errorMessage = '';
      const { appointmentId } = this.selectedAppointment;

      this.loading = true;

      try {
        switch (this.bookingFlow) {
          case VETPANEL_FOLLOWUP_FLOW:
            await await this.confirmBooking(appointmentId);
            break;
          case TWENTY_TWO_EIGHTY_EIGHT_BOOKING_FLOW:
            await this.sendConfirmTextMessage();
            break;
        }
        this.$emit('next-page');
      } catch (error) {
        console.error(error);
        errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async sendConfirmTextMessage() {
      try {
        await this.sendAppointmentConfirmationLink();
      } catch (error) {
        this.errorMessage = error.message;
      }
    },

    async confirmBooking(appointmentId) {
      const params = {
        status: this.statusHelper(),
      };

      await RemoteBookingApi.confirmBooking(appointmentId, params);

      const updatedSelectedAppointment = {
        ...this.selectedAppointment,
        status: 'completed',
      };

      if (this.previousAppointmentId) {
        const bookingMetaParams = {
          referenceAppointmentId: this.previousAppointmentId,
          type: 'follow-up',
        };

        await RemoteBookingApi.createOrUpdateBookingMetaData(
          appointmentId,
          bookingMetaParams
        );
      }

      this.setSelectedAppointment(updatedSelectedAppointment);
    },

    statusHelper() {
      const { freeStatus } = this.selectedService;

      if (freeStatus.freeForUser) {
        return 'free';
      }

      if (freeStatus.hasSubscription) {
        return 'subscription';
      }

      return 'insurance';
    },
  },
};
</script>

<style></style>
