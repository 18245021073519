<template>
  <div>
    <dialog-header
      title="Describe the case"
      description="Please describe the case in as much detail as possible, so our vet can help you in the best way."
    />
    <section-container :loading="loading">
      <ValidationObserver ref="observer" @submit.prevent="">
        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="caseDescription"
          vid="caseDescription"
          class="w-full"
        >
          <textarea-input
            id="caseDescription"
            v-model="caseDescription"
            name="caseDescription"
            autocomplete="off"
            type="text"
            :rows="10"
          />
          <transition name="fade">
            <span
              v-if="showValidationErrors && errors.length"
              class="block text-center font-semibold text-red-800"
            >
              {{ errors[0] }}
            </span>
          </transition>
        </ValidationProvider>
      </ValidationObserver>

      <base-button
        class="mt-auto w-full"
        color="primary"
        size="lg"
        @click="validate"
      >
        Continue <fv-icon class="text-xs text-white" icon="chevron-right" />
      </base-button>
    </section-container>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { RemoteBookingApi } from '@/api';
import { errorHandler } from '@/utils/error-handler';
import DialogHeader from '../DialogHeader.vue';
import SectionContainer from '../SectionContainer.vue';

export default {
  components: {
    DialogHeader,
    SectionContainer,
  },
  data() {
    return {
      showValidationErrors: false,
      caseDescription: null,
      loading: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('remoteBooking', {
      selectedAppointment: 'selectedAppointment',
    }),
  },
  methods: {
    ...mapMutations('remoteBooking', ['setSelectedAppointment']),
    async validate() {
      this.showValidationErrors = false;
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.handleUpdateDescription();
      } else {
        this.showValidationErrors = true;
      }
    },
    async handleUpdateDescription() {
      const { appointmentId } = this.selectedAppointment;
      const preFilledDescription = `\n
      This case description was written by ${this.user.display_name})`;
      const params = {
        description: this.caseDescription + preFilledDescription,
      };

      this.loading = true;

      try {
        await RemoteBookingApi.updateAppointment(appointmentId, params);

        const updateSelectedAppiontment = {
          ...this.selectedAppointment,
          description: this.caseDescription,
        };
        this.setSelectedAppointment(updateSelectedAppiontment);
        this.$emit('next-page');
      } catch (error) {
        errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.sectionContainer {
  min-height: 450px;
}
</style>
