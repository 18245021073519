const animalTypeHashMap = {
  cat: 2,
  katt: 2,
  dog: 1,
  hund: 1,
  rabbit: 3,
  kanin: 3,
  bird: 4,
  fågel: 4,
  reptile: 5,
  reptil: 5,
  rodent: 6,
  gnagare: 6,
  ferret: 7,
  iller: 7,
  minipig: 8,
  minigris: 8,
  insect_arachnida: 9,
  spindel: 9,
  spider: 9,
  'insekt/spindeldjur': 9,
  horse: 10,
  häst: 10,
  fish: 11,
  fisk: 11,
};

export default function getAnimalTypeIdFromKeyString(
  key: string
): number | null {
  const lowerCasedKey = key.toLowerCase();

  const isOnMap = animalTypeHashMap.hasOwnProperty(lowerCasedKey);
  if (!isOnMap) {
    return null;
  }

  return animalTypeHashMap[lowerCasedKey];
}
