<template>
  <div>
    <dialog-header
      title="Success! Booking completed."
      description="We have sent the booking information to the pet parent."
    />
    <section-container>
      <div class="w-full rounded border border-gray-200 p-4">
        <h3 class="py-4 font-semibold">What's next?</h3>
        <p class="text-gray-500">
          The meeting will take place in our app and the booking information has
          been sent to the pet parent.
        </p>
        <br />
        <p class="text-gray-500">
          Pet parent has to download the app from app store / play store. After
          logging in they will be able to access their account and the
          appointment.
        </p>
        <br />
        <p class="text-gray-500">
          They will find the booking on the home screen of the app.
        </p>
      </div>
      <base-button
        class="mt-auto w-full"
        color="primary"
        size="lg"
        @click="event => this.$emit('close')"
        >Back to meeting room</base-button
      >
    </section-container>
  </div>
</template>

<script>
import DialogHeader from '../DialogHeader.vue';
import SectionContainer from '../SectionContainer.vue';

export default {
  components: {
    DialogHeader,
    SectionContainer,
  },
};
</script>

<style scoped lang="postcss">
.sectionContainer {
  min-height: 450px;
}
</style>
