<template>
  <div>
    <dialog-header
      title="Success! Message sent"
      description="We have sent an SMS with a link to a booking confirmation page. Ask if the pet parent has received a message, and let them know they need to confirm the booking for it to go through."
    />
    <section-container :loading="loading">
      <div v-if="errorMessage" class="font-semibold text-red-900">
        {{ errorMessage }}
      </div>

      <div class="divide-y rounded border border-gray-200">
        <div class="flex w-full items-center justify-between">
          <p class="px-4 py-5">Status</p>
          <p
            v-if="!shortLinkHasBeenOpened"
            class="flex items-center px-4 text-gray-500"
          >
            <fv-icon icon="dot-loader" size="xxl" class="ml-5" />Waiting for
            confirmation
          </p>
          <p
            v-if="shortLinkHasBeenOpened"
            class="flex items-center px-4 text-fv-green"
          >
            <fv-icon icon="check-mark" size="md" class="ml-5" /> Opened
          </p>
        </div>
        <div class="flex w-full items-center justify-between">
          <p v-if="!shortLinkHasBeenOpened" class="px-4 py-5">
            SMS not received?
          </p>
          <text-button
            v-if="!shortLinkHasBeenOpened"
            color="primary"
            size="lg"
            class="px-4 py-5"
            @click="handleSMS"
            >Send again</text-button
          >
        </div>

        <div class="w-full rounded border border-gray-200 p-4">
          <h3 class="py-4 font-semibold">What's next?</h3>
          <p class="text-gray-500">
            The meeting will take place in our app and the booking information
            has been sent to the pet parent.
          </p>
          <br />
          <p class="text-gray-500">
            Pet parent has to download the app from app store / play store.
            After logging in they will be able to access their account and the
            appointment.
          </p>
          <br />
          <p class="text-gray-500">
            They will find the booking on the home screen of the app.
          </p>
        </div>
      </div>
      <text-button
        color="primary"
        size="lg"
        class="mt-10"
        @click="event => this.$emit('first-page')"
        >Book for another pet parent</text-button
      >
    </section-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { RemoteBookingApi } from '@/api';
import DialogHeader from '../DialogHeader.vue';
import SectionContainer from '../SectionContainer.vue';

export default {
  components: {
    DialogHeader,
    SectionContainer,
  },

  data() {
    return {
      loading: false,
      errorMessage: '',
      shortLinkHasBeenOpened: null,
      shortLinkStatusInterval: null,
    };
  },
  computed: {
    ...mapState('remoteBooking', ['selectedAppointment']),
  },
  mounted() {
    this.handleShortLinkStatusUpdate();
  },
  beforeDestroy() {
    clearInterval(this.shortLinkStatusInterval);
    this.shortLinkStatusInterval = null;
  },

  methods: {
    ...mapActions('remoteBooking', ['sendAppointmentConfirmationLink']),

    handleShortLinkStatusUpdate() {
      if (!this.selectedAppointment.appointmentId) {
        if (this.shortLinkStatusInterval)
          clearInterval(this.shortLinkStatusInterval);
      }
      const shortLinkStatusInterval = setInterval(
        this.getShortLinkStatus,
        5000
      );
      this.shortLinkStatusInterval = shortLinkStatusInterval;

      if (this.shortLinkHasBeenOpened) {
        clearInterval(this.shortLinkStatusInterval);
        this.shortLinkStatusInterval = null;
      }
    },

    async handleSMS() {
      this.loading = true;
      this.errorMessage = '';
      try {
        await this.sendAppointmentConfirmationLink();
      } catch (error) {
        console.error(error);
        this.errorMessage = error.message;
      } finally {
        this.loading = false;
      }
    },
    async getShortLinkStatus() {
      try {
        const { data } = await RemoteBookingApi.getAppointmentShortLinkStatus(
          this.selectedAppointment.appointmentId
        );
        this.shortLinkHasBeenOpened = data.status;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
.dot1,
.dot2,
.dot3 {
  animation: bounce 1s infinite ease-in-out;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
