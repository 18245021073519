<template>
  <div class="text-center">
    <dialog-header
      title="Select a time"
      :description="`Select a time for the appointment.\nThe meeting will take place in our app.`"
    />
    <section-container :loading="loading">
      <h3 class="mb-2 text-lg">{{ selectedService.name }}</h3>
      <div class="mt-3 mb-3 flex items-center space-x-3">
        <datepicker
          :value="selectedDate"
          format="D d MMMM"
          input-class="flex flex-1 font-black bg-white
      border rounded cursor-pointer focus:outline-none py-0 h-10 hover:bg-gray-50"
          wrapper-class="top-0 flex-1"
          calendar-class="font-normal rounded shadow uppercase text-sm left-auto"
          :monday-first="true"
          minimum-view="day"
          maximum-view="month"
          placeholder="Select a day"
          :disabled-dates="disabledDates"
          @input="$event => handleSelectedDate($event)"
        />
        <v-select
          v-model="selectedTZ"
          class="v-input flex-1 hover:bg-gray-50"
          :append-to-body="true"
          :clearable="false"
          :searchable="true"
          :options="timeZones"
          :value="selectedTZ"
          @input="handleSelectedTimeZone"
        />
        <v-select
          v-model="selectedVet"
          class="v-input flex-1 hover:bg-gray-50"
          :append-to-body="true"
          position="top"
          :clearable="true"
          :searchable="true"
          label="name"
          :reduce="opt => opt.id"
          :options="availableVets"
          @input="handleSelectedVet"
        />
      </div>
      <list-item v-if="availableSlots.length > 0">
        <button
          v-for="slot in availableSlots"
          :key="slot.id"
          class="group w-full px-2 py-1 hover:bg-gray-50"
          @click="event => handleSelectSlot(slot.id)"
        >
          <div class="flex items-center justify-between p-1">
            <div class="text-left">
              <p class="mb-1 font-semibold">
                {{ formatForCustomer(slot.dateTime) }}
              </p>
              <p class="text-gray-500 group-hover:text-gray-700">
                {{ slot.veterinarian.displayName }}
              </p>
            </div>
            <fv-icon class="text-lg text-gray-700" icon="chevron-right" />
          </div>
        </button>
      </list-item>
      <h3 v-else class="text-center">No available slots</h3>
    </section-container>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { RemoteBookingApi, ScheduleApi } from '@/api';
import {
  format,
  startOfDay,
  endOfDay,
  addHours,
  addWeeks,
  subDays,
  isToday,
} from 'date-fns';
import { errorHandler } from '@/utils/error-handler';
import { mapGetters, mapMutations } from 'vuex';
import {
  getTimezonesForCountry,
  getCurrentOffsetForTimeZone,
} from '@/utils/helpers/timezone-helpers';
import DialogHeader from '../DialogHeader.vue';
import SectionContainer from '../SectionContainer.vue';
import ListItem from '../ListItem.vue';

export default {
  components: {
    Datepicker,
    DialogHeader,
    SectionContainer,
    ListItem,
  },
  data() {
    return {
      availableSlots: [],
      selectedDate: new Date(),
      vetList: [],
      selectedVet: null,
      loading: false,
      format,
      selectedTZ: '',
      timeZones: [],
      defaultTimeZones: [
        'America/New_York',
        'America/Los_Angeles',
        'America/Chicago',
        'Europe/Stockholm',
        'Europe/London',
        'Europe/Berlin',
        'Europe/Oslo',
        'Europe/Helsinki',
        'Europe/Copenhagen',
      ],
    };
  },
  computed: {
    ...mapGetters('appointment', {
      appointmentUser: 'getAppointmentUser',
      appointmentTimeZone: 'getTimezone',
    }),
    ...mapGetters('user', { user: 'getUser' }),
    ...mapGetters('auth', { isVet: 'isVet' }),
    ...mapGetters('remoteBooking', {
      selectedAnimal: 'getSelectedAnimal',
      selectedService: 'getSelectedService',
      selectedUser: 'getSelectedUser',
    }),

    disabledDates() {
      return {
        to: subDays(new Date(), 1),
        from: addWeeks(new Date(), 5),
      };
    },

    countryId() {
      return this.selectedUser.country_id ?? this.selectedUser.countryId;
    },

    availableVets() {
      let result = this.vetList.filter(vet => vet.id !== this.user.id);

      const currentUserIsInList = this.vetList.find(
        vet => vet.id === this.user.id
      );

      if (this.isVet && currentUserIsInList) {
        const me = { id: this.user.id, name: 'Me' };

        result.unshift(me);
      }

      result.unshift({ id: null, name: 'Any' });

      return result;
    },
  },
  async mounted() {
    this.timeZones = this.appointmentUser?.country?.name
      ? getTimezonesForCountry(this.appointmentUser.country.name)
      : this.defaultTimeZones;

    if (
      this.appointmentTimeZone &&
      this.timeZones.includes(this.appointmentTimeZone)
    ) {
      this.selectedTZ = this.appointmentTimeZone;
    } else if (
      this.appointmentUser?.country?.timeZone &&
      this.timeZones.includes(this.appointmentUser.country.timeZone)
    ) {
      this.selectedTZ = this.appointmentUser.country.timeZone;
    } else {
      this.selectedTZ = this.timeZones[0];
    }

    await Promise.all([this.getSlots(), this.getVets()]);
  },
  methods: {
    ...mapMutations('remoteBooking', {
      setSelectedAppointment: 'setSelectedAppointment',
    }),
    handleSelectedVet() {
      this.getSlots();
    },

    handleSelectedDate(date) {
      this.selectedDate = date;
      this.getSlots();
    },

    handleSelectedTimeZone() {
      this.getSlots();
    },

    async handleSelectSlot(slotId) {
      this.loading = true;
      const serviceId = this.selectedService.id;
      const animalId = this.selectedAnimal.id;
      const userId = this.selectedUser.id;

      const params = {
        serviceId,
        animalId,
        userId,
        funnel: 'vetpanel-remote-booking',
      };

      try {
        const { data: appointmentData } =
          await RemoteBookingApi.createAppointment(params);
        const { id } = appointmentData.data;
        const reserveParams = {
          reservationId: slotId,
        };

        const { data: reservationData } = await RemoteBookingApi.reserveSlot(
          id,
          reserveParams
        );

        const updateSelectedAppointment = {
          appointmentId: id,
          slotTime: reservationData.data.dateTime,
          vet: reservationData.data.veterinarian.displayName,
          status: 'pending',
        };

        this.setSelectedAppointment(updateSelectedAppointment);
        this.$emit('next-page');
      } catch (error) {
        errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async getSlots() {
      this.loading = true;
      // Since JavaScript's Date object is based on the user's local time zone,
      // we need to offset the selected date to the customer's time zone
      // when doing time checks and manipulation.
      // We then need to offset the date back to the vet's time zone
      // before converting it to an ISO string for the API.
      const offset = this.getVetCustomerOffset();
      const reverseOffset = offset * -1;
      const offsetDate = this.getOffsetDate(this.selectedDate, offset);

      let fromDateTime;

      if (isToday(offsetDate)) {
        // Add 1 hour buffer if selected date is the current date
        if (isToday(addHours(offsetDate, 1))) {
          fromDateTime = addHours(offsetDate, 1);
        } else {
          // If the added hour causes the selectedDate to be tomorrow,
          // set the fromDateTime to the end of the day instead
          fromDateTime = endOfDay(offsetDate);
        }
      } else {
        fromDateTime = startOfDay(offsetDate);
      }

      fromDateTime = this.getOffsetDate(
        fromDateTime,
        reverseOffset
      ).toISOString();
      const toDateTime = this.getOffsetDate(
        endOfDay(offsetDate),
        reverseOffset
      ).toISOString();
      const animalTypeId = this.selectedAnimal.animalType.id;
      const countryId = this.countryId;
      const serviceId = this.selectedService.id;

      const params = {
        serviceId,
        fromDateTime,
        toDateTime,
        countryId,
        animalTypeId,
      };

      if (this.selectedVet) {
        params.vetId = this.selectedVet;
      }

      try {
        const { data } = await RemoteBookingApi.fetchSlots(params);
        this.availableSlots = [...data.data];
      } catch (error) {
        errorHandler(error);
      } finally {
        this.loading = false;
      }
    },

    async getVets() {
      const params = {
        countryId: this.countryId,
        animalTypeId: this.selectedAnimal.type_id,
        serviceId: this.selectedService.id,
      };

      try {
        const { data } = await ScheduleApi.fetchScheduleVets(params);

        this.vetList = data;
      } catch (error) {
        errorHandler(error);
      }
    },

    getVetCustomerOffset() {
      const customerOffset = getCurrentOffsetForTimeZone(this.selectedTZ);
      const vetOffset = new Date().getTimezoneOffset() * -1;
      return customerOffset - vetOffset;
    },

    getOffsetDate(date, offset) {
      const offsetDate = new Date(date.getTime());
      offsetDate.setMinutes(offsetDate.getMinutes() + offset);
      return offsetDate;
    },

    formatForCustomer(date) {
      const offset = this.getVetCustomerOffset();
      const offsetDate = this.getOffsetDate(new Date(date), offset);
      return format(offsetDate, 'HH:mm');
    },
  },
};
</script>

<style></style>
