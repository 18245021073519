<template>
  <div>
    <dialog-header title="Account and insurance policy lookup" />

    <section-container :is-gray="true" :loading="loading">
      <div class="borderContent rounded border border-gray-200 px-2 pt-4">
        <div class="flex items-center justify-between pb-4">
          <base-label for="social_security_number" class="w-full">
            Social security number
          </base-label>
          <base-input
            id="social_security_number"
            v-model="ssnInputHandler"
            name="social_security_number"
            placeholder="YYYYMMDD-NNNN"
            class="mt-1 text-right"
            :border="false"
          />
        </div>
        <div class="flex items-center justify-between pb-4">
          <div class="flex w-full items-center">
            <base-label for="phone_number"> Phone number </base-label>
          </div>
          <div class="flex w-full items-center border-0">
            <select
              id="countryCodes"
              v-model="countryCodeInputHandler"
              name="countryCodes"
            >
              <option
                v-for="code in countryCodes"
                :key="code.id"
                :value="code.code"
              >
                + {{ code.code }}
              </option>
            </select>
            <base-input
              id="number"
              v-model="mobilePhoneInputHandler"
              name="Phone Number"
              placeholder="### ### ## ##"
              class="mt-1 border-0 text-right"
              :border="false"
            />
          </div>
        </div>
        <div class="flex items-center justify-between pb-4">
          <base-label for="insurance_company"> Insurance company </base-label>

          <v-select
            id="insurance_company"
            v-model="selectedPartnerHandler"
            :options="partners"
            placeholder="Select an insurance company"
            :loading="loading"
            :clearable="false"
            label="name"
            class="w-1/2"
          />
          <!-- :value="selectedPartner"

            :reduce="opt => opt.id" -->

          <!-- <select
            id="insuranceCompanies"
            v-model="selectedPartnerHandler"
            name="insuranceCompanies"
          >
            <option
              v-for="partner in partners"
              :key="partner.id"
              :value="partner"
            >
              {{ partner ? partner.insurance.name : '' }}
            </option>
          </select> -->
        </div>
      </div>
      <base-button
        class="mt-auto w-full"
        color="primary"
        size="lg"
        :disabled="isDisable"
        @click="handleRegister"
        >Check for valid policy
        <fv-icon class="text-lg text-white" icon="chevron-right"
      /></base-button>
    </section-container>
  </div>
</template>

<script>
import { PartnerApi } from '@/api';
import { mapState, mapActions, mapMutations } from 'vuex';
import DialogHeader from '../DialogHeader.vue';
import SectionContainer from '../SectionContainer.vue';

export default {
  components: {
    DialogHeader,
    SectionContainer,
  },
  data() {
    return {
      partners: [],
      loading: false,
    };
  },
  computed: {
    ...mapState('admin', ['countryId']),
    ...mapState('remoteBooking', [
      'selectedUser',
      'userAnimalList',
      'lookupAnimalList',
      'selectedPartner',
      'userInput',
      'countryCodes',
    ]),

    mobilePhoneFormatted() {
      return `${this.userInput.countryCode}${parseInt(
        this.userInput.mobilePhone
      )}`;
    },

    isDisable() {
      if (
        this.userInput.ssn !== '' &&
        this.userInput.countryCode !== '' &&
        this.userInput.mobilePhone !== '' &&
        !!this.selectedPartner
      ) {
        return false;
      } else {
        return true;
      }
    },

    selectedPartnerHandler: {
      get() {
        return this.selectedPartner;
      },
      set(partner) {
        this.setSelectedPartner(partner);
      },
    },

    ssnInputHandler: {
      get() {
        return this.userInput.ssn;
      },
      set(ssn) {
        this.setUserInputSsn(ssn);
      },
    },
    mobilePhoneInputHandler: {
      get() {
        return this.userInput.mobilePhone;
      },
      set(mobilePhone) {
        this.setUserInputMobilePhone(mobilePhone);
      },
    },
    countryCodeInputHandler: {
      get() {
        return this.userInput.countryCode;
      },
      set(countryCode) {
        this.setUserInputCountryCode(countryCode);
      },
    },
  },

  async mounted() {
    await Promise.all([this.fetchPartners(), this.fetchCountryCodes()]);
  },

  methods: {
    ...mapMutations('remoteBooking', [
      'setSelectedPartner',
      'setUserInputSsn',
      'setUserInputCountryCode',
      'setUserInputMobilePhone',
      'setUserAnimalList',
      'setLookupAnimalList',
    ]),
    ...mapActions('remoteBooking', [
      'fetchUserByAttributes',
      'lookupPolicy',
      'fetchCountryCodes',
    ]),

    async fetchPartners() {
      const { data } = await PartnerApi.fetchPartners(this.countryId);

      const partners = data.partners.map(partner => {
        return { name: partner.insurance.name, ...partner };
      });
      this.partners = partners;
    },

    async fetchUser() {
      try {
        await this.fetchUserByAttributes();
      } catch (error) {
        return; // Swallow error, not finding an account should continue the user journey
      }
    },

    async lookup() {
      await this.lookupPolicy();
    },
    async handleRegister() {
      this.loading = true;
      this.setUserAnimalList([]);
      this.setLookupAnimalList([]);
      try {
        await Promise.all([this.fetchUser(), this.lookup()]);
        this.$emit('next-page');
      } catch (error) {
        this.$emit('next-page');
        return;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.sectionContainer {
  min-height: 450px;
}
#countryCodes {
  margin-left: 5px;
}

#insuranceCompanies {
  margin-right: 12px;
}
</style>
