var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dialog-header", {
        attrs: {
          title: "Select a service",
          description:
            _vm.insuranceCompanyName +
            " includes access to the following FirstVet services",
        },
      }),
      _c(
        "section-container",
        { attrs: { loading: _vm.loading } },
        [
          _vm.availableServices.length || !_vm.loading
            ? _c(
                "list-item",
                _vm._l(_vm.servicesOrdered, function (service) {
                  return _c(
                    "button",
                    {
                      key: service.id,
                      staticClass: "w-full px-2 py-4 hover:bg-gray-50",
                      class: {
                        "cursor-not-allowed line-through opacity-50":
                          !service.isFree,
                      },
                      attrs: { disabled: !service.isFree },
                      on: {
                        click: function (event) {
                          return _vm.handleSelectService(service.id)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex items-center justify-between p-2",
                        },
                        [
                          _c("div", { staticClass: "text-left" }, [
                            _c("p", { staticClass: "mb-2 font-semibold" }, [
                              _vm._v(_vm._s(service.name)),
                            ]),
                            _c("p", { staticClass: "text-xs text-gray-500" }, [
                              _vm._v(_vm._s(service.description)),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: service.isFree,
                                  expression: "service.isFree",
                                },
                              ],
                              staticClass: "flex items-center gap-x-2",
                            },
                            [
                              _c("Label", { attrs: { "label-text": "Free" } }),
                              _c("fv-icon", {
                                staticClass: "text-lg text-gray-700",
                                attrs: { icon: "chevron-right" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("h3", { staticClass: "text-center text-3xl" }, [
                _vm._v("No available services"),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }