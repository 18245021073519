var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dialog-header", {
        attrs: {
          title: "Success! Message sent",
          description:
            "We have sent an SMS with a link to a booking confirmation page. Ask if the pet parent has received a message, and let them know they need to confirm the booking for it to go through.",
        },
      }),
      _c(
        "section-container",
        { attrs: { loading: _vm.loading } },
        [
          _vm.errorMessage
            ? _c("div", { staticClass: "font-semibold text-red-900" }, [
                _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "divide-y rounded border border-gray-200" },
            [
              _c(
                "div",
                { staticClass: "flex w-full items-center justify-between" },
                [
                  _c("p", { staticClass: "px-4 py-5" }, [_vm._v("Status")]),
                  !_vm.shortLinkHasBeenOpened
                    ? _c(
                        "p",
                        { staticClass: "flex items-center px-4 text-gray-500" },
                        [
                          _c("fv-icon", {
                            staticClass: "ml-5",
                            attrs: { icon: "dot-loader", size: "xxl" },
                          }),
                          _vm._v("Waiting for confirmation "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.shortLinkHasBeenOpened
                    ? _c(
                        "p",
                        { staticClass: "flex items-center px-4 text-fv-green" },
                        [
                          _c("fv-icon", {
                            staticClass: "ml-5",
                            attrs: { icon: "check-mark", size: "md" },
                          }),
                          _vm._v(" Opened "),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                { staticClass: "flex w-full items-center justify-between" },
                [
                  !_vm.shortLinkHasBeenOpened
                    ? _c("p", { staticClass: "px-4 py-5" }, [
                        _vm._v(" SMS not received? "),
                      ])
                    : _vm._e(),
                  !_vm.shortLinkHasBeenOpened
                    ? _c(
                        "text-button",
                        {
                          staticClass: "px-4 py-5",
                          attrs: { color: "primary", size: "lg" },
                          on: { click: _vm.handleSMS },
                        },
                        [_vm._v("Send again")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "w-full rounded border border-gray-200 p-4" },
                [
                  _c("h3", { staticClass: "py-4 font-semibold" }, [
                    _vm._v("What's next?"),
                  ]),
                  _c("p", { staticClass: "text-gray-500" }, [
                    _vm._v(
                      " The meeting will take place in our app and the booking information has been sent to the pet parent. "
                    ),
                  ]),
                  _c("br"),
                  _c("p", { staticClass: "text-gray-500" }, [
                    _vm._v(
                      " Pet parent has to download the app from app store / play store. After logging in they will be able to access their account and the appointment. "
                    ),
                  ]),
                  _c("br"),
                  _c("p", { staticClass: "text-gray-500" }, [
                    _vm._v(
                      " They will find the booking on the home screen of the app. "
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "text-button",
            {
              staticClass: "mt-10",
              attrs: { color: "primary", size: "lg" },
              on: {
                click: function (event) {
                  return this$1.$emit("first-page")
                },
              },
            },
            [_vm._v("Book for another pet parent")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }